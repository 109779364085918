import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ArrowRight: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M14.53 5.47l-1.06 1.06 4.72 4.72H3v1.5h15.19l-4.72 4.72 1.06 1.06L21.06 12l-6.53-6.53z'
      />
    </svg>
  </i>
);

ArrowRight.displayName = 'ArrowRight';
export default ArrowRight;
