import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Sandbox: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.74 14a.8.8 0 00-.07-.34L17.23 8.9a1.27 1.27 0 00-1.1-.65H14v1.5h2l1.79 3.5h-3A5.17 5.17 0 0015 12h-2.25V4.75H14v-1.5h-4v1.5h1.25V12H9a5.17 5.17 0 00.19 1.25h-3L8 9.75h2v-1.5H7.87a1.29 1.29 0 00-1.12.68l-2.42 4.73a.8.8 0 00-.07.34v4.5a1.25 1.25 0 001.24 1.25h13a1.25 1.25 0 001.25-1.25V14zm-14 4.25v-3.5h12.5v3.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Sandbox.displayName = 'Sandbox';
export default Sandbox;
