import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Attachment: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M8.53 19.75a4.29 4.29 0 01-3-7.31l6.91-6.94a4.28 4.28 0 017 4.52l-1.4-.52a2.78 2.78 0 00-4.54-2.93L6.56 13.5a2.79 2.79 0 000 3.93 2.84 2.84 0 003.94 0l5.37-5.37a1 1 0 00.3-.72 1 1 0 00-.3-.72 1 1 0 00-1.43 0l-6 6-1.06-1.06 6-6a2.49 2.49 0 011.78-.73 2.52 2.52 0 011.78 4.29l-5.38 5.38a4.28 4.28 0 01-3.03 1.25z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Attachment.displayName = 'Attachment';
export default Attachment;
