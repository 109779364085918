import React from 'react';

import styles from './LoadingSpinner.module.scss';

export const LoadingSpinner: React.FC = () => (
  <div className={styles.bitsWrapper}>
    <div className={styles.bitsLoader}>
      <div className={styles.bits}>
        <div className={styles.bitsBitWrap1}>
          <svg
            className={styles.bitsCircleBit}
            width='6px'
            height='6px'
            viewBox='0 0 6 6'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle
              fill='none'
              stroke='#111'
              strokeWidth='1'
              strokeLinecap='round'
              cx='3'
              cy='3'
              r='2'
            />
          </svg>
        </div>
        <div className={styles.bitsBitWrap2}>
          <svg
            className={styles.bitsLineBit}
            width='6px'
            height='2px'
            viewBox='0 0 4 1'
          >
            <line x1='0' y1='0' x2='4' y2='0' stroke='black' />
          </svg>
        </div>
        <div className={styles.bitsBitWrap3}>
          <svg
            className={styles.bitsPlusBit}
            width='6px'
            height='6px'
            viewBox='0 0 4 4'
          >
            <g>
              <line
                x1='0'
                y1='2'
                x2='4'
                y2='2'
                stroke='black'
                strokeWidth='1'
              />
              <line
                x1='2'
                y1='0'
                x2='2'
                y2='4'
                stroke='black'
                strokeWidth='1'
              />
            </g>
          </svg>
        </div>
      </div>
      <svg
        className={styles.bitsSpinner}
        width='48px'
        height='48px'
        viewBox='0 0 48 48'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          className={styles.bitsPath}
          fill='none'
          strokeWidth='2'
          strokeLinecap='round'
          cx='24'
          cy='24'
          r='22'
        />
      </svg>
    </div>
  </div>
);

LoadingSpinner.displayName = 'LoadingSpinner';
export default LoadingSpinner;
export { styles };
