import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Book: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg id='Icons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path id='icon-container' d='M0 0h24v24H0z' fill='none' />
      <path
        d='M18 6.25H7.5a.75.75 0 010-1.5H18v-1.5H7.5A2.25 2.25 0 005.25 5.5v13a2.25 2.25 0 002.25 2.25H18a.76.76 0 00.75-.75V7a.76.76 0 00-.75-.75zm-.75 13H7.5a.76.76 0 01-.75-.75V7.62a2.08 2.08 0 00.75.13H12V12l1.5-1.26L15 12V7.75h2.25z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Book.displayName = 'Book';
export default Book;
