import React, { FunctionComponent } from 'react';

import BaseInput, { InputProps } from '../BaseInput';

export const NumberInput: FunctionComponent<InputProps> = props => (
  <BaseInput type='number' pattern='[0-9]*' {...props} />
);

NumberInput.displayName = 'NumberInput';
export default NumberInput;
