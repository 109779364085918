import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const LineGraph: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M21 18.25v1.5H3v-1.5zM7 11.13l2.59 2.06a1.23 1.23 0 001.06.24 1.29 1.29 0 00.85-.68l2.15-4.57 2.56 2a1.25 1.25 0 001.87-.36l2.53-4.49-1.3-.74L17 8.87l-2.63-2.06a1.23 1.23 0 00-1.06-.24 1.29 1.29 0 00-.85.68l-2.15 4.57-2.56-2a1.25 1.25 0 00-1.87.36l-2.53 4.45 1.3.74z'
        fill='currentColor'
      />
    </svg>
  </i>
);

LineGraph.displayName = 'LineGraph';
export default LineGraph;
