import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Terminal: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.5 4.25h-15A1.25 1.25 0 003.25 5.5v12a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25v-12a1.25 1.25 0 00-1.25-1.25zm-.25 3H7.75v-1.5h11.5zm-13-1.5v1.5h-1.5v-1.5zm-1.5 11.5v-8.5h14.5v8.5zm2.78-6.78L10.06 13l-2.53 2.53-1.06-1.06L7.94 13l-1.47-1.47zM12 14.25h5v1.5h-5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Terminal.displayName = 'Terminal';
export default Terminal;
