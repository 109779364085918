import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Lightbulb: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M16.31 4.78a6.84 6.84 0 10-8.15 11 1.13 1.13 0 01.47.71L9 19.65a1.25 1.25 0 001.25 1.1h3.48a1.24 1.24 0 001.27-1.1l.39-3.16a1.09 1.09 0 01.46-.73 6.84 6.84 0 00.48-11zm-5.83 14.47l-.18-1.5h3.4l-.18 1.5zM15 14.52a2.62 2.62 0 00-1.1 1.73h-1.15v-3.94l1.78-1.78-1.06-1.06L12 10.94l-1.47-1.47-1.06 1.06 1.78 1.78v3.94h-1.14A2.59 2.59 0 009 14.51a5.34 5.34 0 116 0z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Lightbulb.displayName = 'Lightbulb';
export default Lightbulb;
