import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Safe: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M8.53 15.53L10.09 14a2.73 2.73 0 003.82 0l1.56 1.56 1.06-1.06-1.85-1.86a3 3 0 00.07-.61 2.75 2.75 0 00-2-2.63V7h-1.5v2.37a2.75 2.75 0 00-2 2.63 3 3 0 00.07.61l-1.85 1.86zM12 10.75A1.25 1.25 0 1110.75 12 1.25 1.25 0 0112 10.75zm6.5-6.5h-13A1.25 1.25 0 004.25 5.5v13a1.25 1.25 0 001.25 1.25h13a1.25 1.25 0 001.25-1.25v-13a1.25 1.25 0 00-1.25-1.25zm-.25 14H5.75V5.75h12.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Safe.displayName = 'Safe';
export default Safe;
