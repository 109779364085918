import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Users: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M16 13a3 3 0 003-2.95V10a3 3 0 00-6 0 3 3 0 003 3zm-1.5-3a1.5 1.5 0 013 0 1.5 1.5 0 01-3 0zm7.26 7.43V19h-1.5v-1.57c0-1-.15-1.64-2.14-1.68h-4.26c-2 0-2.12.69-2.12 1.68V19h-1.5v-1.57a2.71 2.71 0 011.87-2.91c-.18-.44-.66-.74-2-.77H5.86c-2 0-2.12.69-2.12 1.68V17h-1.5v-1.57c0-2.12 1.15-3.13 3.61-3.18h4.29c1.3 0 3 .28 3.46 2h4.54c2.47.04 3.62 1.06 3.62 3.18zM8 11a3 3 0 003-2.95V8a3 3 0 00-6 0 3 3 0 003 3zM6.5 8a1.5 1.5 0 013 0 1.5 1.5 0 01-3 0z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Users.displayName = 'Users';
export default Users;
