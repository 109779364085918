import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const MenuS1: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M19 6.25v1.5H5v-1.5h14zm-14 6.5h14v-1.5H5v1.5zm0 5h14v-1.5H5v1.5z'
        />
      </g>
    </svg>
  </i>
);

MenuS1.displayName = 'MenuS1';
export default MenuS1;
