import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const AppearanceShow: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 19a7.47 7.47 0 01-5.3-2.19l-3.88-3.93a1.26 1.26 0 010-1.76L6.7 7.24a7.51 7.51 0 0110.6 0l3.88 3.88a1.26 1.26 0 010 1.76l-3.88 3.88A7.47 7.47 0 0112 19zm-7.94-7l3.7 3.7a6 6 0 008.48 0l3.7-3.7-3.7-3.7a6 6 0 00-8.48 0zM12 15.75A3.75 3.75 0 1115.75 12 3.75 3.75 0 0112 15.75zm0-6A2.25 2.25 0 1014.25 12 2.25 2.25 0 0012 9.75z'
        fill='currentColor'
      />
    </svg>
  </i>
);

AppearanceShow.displayName = 'AppearanceShow';
export default AppearanceShow;
export { styles };
