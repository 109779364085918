import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Products: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M18.5 4.25h-10A1.25 1.25 0 007.25 5.5v10a1.25 1.25 0 001.25 1.25h10a1.25 1.25 0 001.25-1.25v-10a1.25 1.25 0 00-1.25-1.25zm-.25 3h-6.5v-1.5h6.5zm-8-1.5v1.5h-1.5v-1.5zm-1.5 9.5v-6.5h9.5v6.5zm-3 3H16v1.5H5.5a1.25 1.25 0 01-1.25-1.25V9h1.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Products.displayName = 'Products';
export default Products;
