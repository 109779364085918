import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const AppearanceHide: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M21.18 11.12L17.3 7.24a7.49 7.49 0 00-9.73-.73l-3-3-1.1 1.02 3 3-3.65 3.59a1.26 1.26 0 000 1.76l3.88 3.88a7.48 7.48 0 009.73.73l3 3 1.06-1.06-3-3 3.62-3.62a1.26 1.26 0 00.07-1.69zM12 17.45a6 6 0 01-4.24-1.75L4.06 12 7.5 8.56l1.37 1.38a3.75 3.75 0 005.19 5.19l1.3 1.29A5.92 5.92 0 0112 17.45zM14 13l-3-3a2.19 2.19 0 011-.23A2.25 2.25 0 0114.25 12a2.19 2.19 0 01-.25 1zm-1 1a2.19 2.19 0 01-1 .23A2.25 2.25 0 019.75 12a2.19 2.19 0 01.25-1zm3.54 1.42l-1.37-1.38a3.75 3.75 0 00-5.23-5.17l-1.3-1.29a6 6 0 017.6.72l3.7 3.7z'
        fill='currentColor'
      />
    </svg>
  </i>
);

AppearanceHide.displayName = 'AppearanceHide';
export default AppearanceHide;
export { styles };
