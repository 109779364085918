import React, { ForwardRefExoticComponent } from 'react';

import BaseInput, { InputProps } from '../BaseInput';

export const TextInput: ForwardRefExoticComponent<InputProps> = React.forwardRef(
  (props, ref) => <BaseInput type='text' ref={ref} {...props} />,
);

TextInput.displayName = 'TextInput';
export default TextInput;
