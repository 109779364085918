import React from 'react';
import cx from 'classnames';

import Touchable from '../Touchable';
import Checkmark from '../Icons/Checkmark';

import styles from './Dropdown.module.scss';

export interface DropdownItem {
  label: string;
  value: string;
}

interface Props {
  block?: boolean;
  children?: Array<React.ReactElement>;
  className?: Parameters<typeof cx>[0];
  isOpen: boolean;
  onChange?: (v: DropdownItem) => void;
  onEscape?: () => void;
  options?: Array<DropdownItem>;
  target: React.ReactNode;
  value?: DropdownItem;
  wrapperClassName?: Parameters<typeof cx>[0];
}

export const Dropdown: React.FC<Props> = ({
  block,
  children,
  className,
  isOpen,
  onChange,
  onEscape,
  options,
  target,
  value,
  wrapperClassName,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isOpen && onEscape) {
      const clickOutsideHandler = (event: MouseEvent) => {
        if (
          ref.current &&
          event.target instanceof Node &&
          !ref.current.contains(event.target)
        ) {
          onEscape();
        }
      };
      document.addEventListener('click', clickOutsideHandler);

      const escapeKeyHandler = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
          onEscape();
        }
      };
      document.addEventListener('keyup', escapeKeyHandler);

      return () => {
        document.removeEventListener('click', clickOutsideHandler);
        document.removeEventListener('keyup', escapeKeyHandler);
      };
    }

    return () => {};
  }, [isOpen, onEscape]);

  return (
    <div
      className={cx(
        styles.dropdownWrapper,
        block && styles.block,
        wrapperClassName,
      )}
      ref={ref}
    >
      {target}
      <div
        role='dialog'
        className={cx(styles.dropdown, isOpen && styles.isOpen, className)}
      >
        <ul className={styles.list}>
          {options &&
            !children &&
            options.map((o, idx) => (
              <li className={styles.item} key={idx}>
                <Touchable
                  className={styles.touchable}
                  onClick={() => onChange && onChange(o)}
                >
                  {o.label}
                  {value && value.value === o.value && <Checkmark />}
                </Touchable>
              </li>
            ))}
          {children &&
            !options &&
            children.map(c => (
              <li className={styles.item}>
                {React.cloneElement(c, {
                  className: cx(styles.touchable, c.props.className),
                })}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

Dropdown.displayName = 'Dropdown';
export default Dropdown;
export { styles };
