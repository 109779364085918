import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Inbox: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M20.61 11.46l-1.76-5.35a1.25 1.25 0 00-1.18-.86H6.36a1.24 1.24 0 00-1.18.86l-1.79 5.35a2.83 2.83 0 00-.14.87v5.17a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25v-5.18a2.82 2.82 0 00-.14-.86zM6.54 6.75h10.94l1.52 4.5h-3.49a1.24 1.24 0 00-1.11.67l-.7 1.33h-3.37l-.7-1.33a1.24 1.24 0 00-1.11-.67H5zm-1.79 10.5v-4.5h3.62l.71 1.34a1.24 1.24 0 001.1.66h3.67a1.22 1.22 0 001.1-.67l.71-1.33h3.59v4.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Inbox.displayName = 'Inbox';
export default Inbox;
