import React from 'react';
import cx from 'classnames';

import styles from './Touchable.module.scss';

export interface TouchableProps {
  ariaChecked?: boolean;
  ariaControls?: string;
  ariaLabel?: string;
  block?: boolean;
  centered?: boolean;
  children?: React.ReactNode;
  className?: string;
  component?: React.ComponentType<any>;
  componentProps?: React.ComponentProps<any>;
  disabled?: boolean;
  hideFocus?: boolean;
  href?: string;
  id?: string;
  inline?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onFocus?: (event: React.MouseEvent) => void;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  onMouseOver?: (event: React.MouseEvent) => void;
  rel?: string;
  role?: string;
  tabIndex?: number;
  target?: string;
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  wide?: boolean;
}

export const Touchable: React.FC<TouchableProps> = ({
  ariaChecked,
  ariaControls,
  ariaLabel,
  block,
  centered,
  children,
  className,
  component,
  componentProps,
  disabled,
  hideFocus,
  href,
  id,
  inline,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  rel,
  role,
  tabIndex,
  target,
  to,
  type = 'button',
  wide,
}) => {
  const props = {
    className: cx(
      styles.resetButtonOrLink,
      inline && styles.inline,
      block && styles.block,
      wide && styles.wide,
      hideFocus && styles.hideFocus,
      centered && styles.centered,
      className,
    ),
    ...componentProps,
  };
  if (component) {
    const Component = component;
    return (
      <Component {...props} to={to} role={role}>
        {children}
      </Component>
    );
  }
  if (href) {
    // TODO(swb) consider a11y concerns of this scenario
    /*eslint jsx-a11y/anchor-has-content: 1 */
    return (
      <a
        {...props}
        aria-label={ariaLabel}
        href={href}
        id={id}
        role={role}
        target={target}
        rel={rel}
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
      >
        {children}
      </a>
    );
  }
  return (
    <button
      {...props}
      id={id}
      onClick={onClick}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      type={type}
      disabled={disabled}
      role={role}
      aria-checked={ariaChecked}
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};

Touchable.displayName = 'Touchable';
export default Touchable;
export { styles };
