import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Gear: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.81 14l-.3-.27a2.38 2.38 0 01-.76-1.73 2.37 2.37 0 01.76-1.73l.3-.27A1.27 1.27 0 0020 8.43l-.93-1.61a1.24 1.24 0 00-1.46-.57l-.53.17a2 2 0 01-1.6-.18l-.3-.17a2 2 0 01-1-1.29l-.12-.55a1.24 1.24 0 00-1.22-1h-1.77a1.24 1.24 0 00-1.22 1l-.09.4a2.39 2.39 0 01-3 1.74l-.38-.12a1.24 1.24 0 00-1.46.57L4 8.43A1.27 1.27 0 004.19 10l.3.27a2.39 2.39 0 010 3.5l-.3.27A1.25 1.25 0 004 15.57l.93 1.61a1.24 1.24 0 001.46.57l.38-.12a2.37 2.37 0 011.92.23 2.32 2.32 0 011.11 1.51l.09.4a1.24 1.24 0 001.22 1h1.86a1.24 1.24 0 001.22-1l.09-.4a2.39 2.39 0 013-1.74l.38.12a1.25 1.25 0 001.46-.57l.88-1.61a1.25 1.25 0 00-.19-1.57zM18 12h-.75a3.86 3.86 0 001.25 2.85l.14.13-.73 1.27-.19-.06a3.89 3.89 0 00-3.12.36 3.8 3.8 0 00-1.82 2.49v.2h-1.51v-.2a3.88 3.88 0 00-4.99-2.84l-.19.06L5.36 15l.14-.13a3.9 3.9 0 000-5.72L5.36 9l.73-1.27.19.06a3.85 3.85 0 003.12-.35A3.8 3.8 0 0011.22 5v-.2h1.46l.08.35a3.53 3.53 0 001.66 2.27l.3.17a3.52 3.52 0 002.8.31l.34-.11.78 1.21-.14.13A3.86 3.86 0 0017.25 12zm-6-3.75A3.75 3.75 0 1015.75 12 3.75 3.75 0 0012 8.25zm0 6A2.25 2.25 0 1114.25 12 2.25 2.25 0 0112 14.25z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Gear.displayName = 'Gear';
export default Gear;
