import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Notebook: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.75 6.05a1.27 1.27 0 00-.4-.9l-1.69-1.57a1.26 1.26 0 00-1.75 0l-.62.63H5.5A1.25 1.25 0 004.25 5.5v1.75H3v1.5h1.25v2.5H3v1.5h1.25v2.5H3v1.5h1.25v1.75a1.25 1.25 0 001.25 1.25h11a1.25 1.25 0 001.25-1.25V8.61L19.38 7a1.28 1.28 0 00.37-.95zm-3.5 12.2H5.75v-1.5H7v-1.5H5.75v-2.5H7v-1.5H5.75v-2.5H7v-1.5H5.75v-1.5h8.05l-2.89 2.93a.65.65 0 00-.21.41l-.44 2.79a.75.75 0 00.74.87h.12l2.77-.44a.73.73 0 00.42-.21l1.94-2zm-2.83-7.39l-1.52.24.24-1.54 4.67-4.71 1.34 1.23z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Notebook.displayName = 'Notebook';
export default Notebook;
