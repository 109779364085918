import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Reset: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M15 7.25H6.81L9 5 8 4 4 8l4 4 1-1-2.19-2.25H15a4.25 4.25 0 010 8.5h-5v1.5h5a5.75 5.75 0 000-11.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Reset.displayName = 'Reset';
export default Reset;
