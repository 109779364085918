import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Copy: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M21.54 11.12l-5.66-5.66a1.24 1.24 0 00-1.76 0l-2.2 2.2-2-2a1.26 1.26 0 00-1.75 0l-5.5 5.5a1.21 1.21 0 000 1.75l5.5 5.5a1.21 1.21 0 001.75 0l2.05-2 2.2 2.2a1.23 1.23 0 001.76 0l5.66-5.66a1.24 1.24 0 00-.05-1.83zM9 17.13L3.87 12 9 6.87l1.85 1.85-2.39 2.4a1.24 1.24 0 000 1.76l2.39 2.4zm6 .17L9.7 12 15 6.7l5.3 5.3z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Copy.displayName = 'Copy';
export default Copy;
