import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ChevronS1Right: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M10.53 17.53l-1.06-1.06L13.94 12 9.47 7.53l1.06-1.06L16.06 12z'
        />
      </g>
    </svg>
  </i>
);

ChevronS1Right.displayName = 'ChevronS1Right';
export default ChevronS1Right;
