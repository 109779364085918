import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Mail: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.5 5.25h-15A1.25 1.25 0 003.25 6.5v11a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25v-11a1.25 1.25 0 00-1.25-1.25zm-.35 1.5L12 13 4.85 6.75zm-14.4 10.5v-8.6L12 15l7.25-6.35v8.6z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Mail.displayName = 'Mail';
export default Mail;
