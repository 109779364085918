import React from 'react';
import cx from 'classnames';
import Information from '../Icons/Information';
import AlertError from '../Icons/AlertError';

import styles from './Callout.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  icon?: React.ReactElement;
  noIcon?: boolean;
  title?: string;
  warning?: boolean;
}

export const Callout: React.FC<Props> = ({
  children,
  title,
  icon,
  noIcon,
  className,
  warning,
}) => {
  const defaultIcon = warning ? (
    <AlertError className={styles.icon} />
  ) : (
    <Information className={styles.icon} />
  );

  return (
    <div className={cx(styles.callout, className)}>
      {!noIcon &&
        (icon
          ? React.cloneElement(icon, { className: styles.icon })
          : defaultIcon)}
      <div>
        {title && <p className={styles.title}>{title}</p>}
        <p className={styles.content}>{children}</p>
      </div>
    </div>
  );
};

Callout.displayName = 'Callout';
export default Callout;
export { styles };
