import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductInvestments: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        data-name='Layer 2'
      >
        <circle cx={12} cy={12} r={8} strokeLinejoin='round' />
        <path
          d='M6.86 17.43l2.28-4.86a.5.5 0 01.68-.23l.09.05 2.81 2.24a.49.49 0 00.68-.05l.06-.09L16 10'
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  </i>
);

ProductInvestments.displayName = 'ProductInvestments';
export default ProductInvestments;
