import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Link: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M18.5 11.67l-1 1.05-1.1-1.06 1-1.05a2.8 2.8 0 000-3.94 2.86 2.86 0 00-4.11-.08L11.4 8.54a2.89 2.89 0 000 4.06l.49.5-1.07 1.06-.49-.5a4.4 4.4 0 010-6.18l1.93-1.94a4.37 4.37 0 016.24 6.13zm-5.32-1.83l-1.07 1.06.49.5a2.89 2.89 0 010 4.06l-1.93 1.95a2.86 2.86 0 01-4.11-.08 2.8 2.8 0 010-3.94l1-1.05-1.02-1.06-1 1.05a4.37 4.37 0 006.24 6.13l1.93-1.94a4.4 4.4 0 000-6.18z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Link.displayName = 'Link';
export default Link;
