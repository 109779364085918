import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductBalance: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinejoin='round'
        strokeWidth='1.5'
        data-name='Layer 2'
      >
        <circle cx={12} cy={12} r={8} />
        <path d='M7.3 9.08h4M7.3 12.08h4M7.3 15.08h4M12.8 12.08h4M12.8 15.08h4' />
      </g>
    </svg>
  </i>
);

ProductBalance.displayName = 'ProductBalance';
export default ProductBalance;
