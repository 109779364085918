import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Remove: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 3.25A8.75 8.75 0 1020.75 12 8.76 8.76 0 0012 3.25zm0 16A7.25 7.25 0 1119.25 12 7.26 7.26 0 0112 19.25zm3.53-9.72L13.06 12l2.47 2.47-1.06 1.06L12 13.06l-2.47 2.47-1.06-1.06L10.94 12 8.47 9.53l1.06-1.06L12 10.94l2.47-2.47z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Remove.displayName = 'Remove';
export default Remove;
