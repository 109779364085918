import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Phone: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M14.65 19.9a10.91 10.91 0 01-7.18-3.37C5.38 14.44 3.32 10.8 4.4 7.41a7.46 7.46 0 013.73-4.05A1.21 1.21 0 019.76 4l1.6 4a1.22 1.22 0 01-.27 1.32l-1.43 1.44A4.71 4.71 0 0011 13.12a4.1 4.1 0 002.24 1.23l1.44-1.44a1.22 1.22 0 011.32-.27l4 1.6a1.21 1.21 0 01.68.67 1.23 1.23 0 010 1 7.49 7.49 0 01-4.05 3.73 6.49 6.49 0 01-1.98.26zm-6.17-15a6 6 0 00-2.65 3c-.93 2.9 1.26 6.1 2.7 7.57s4.67 3.63 7.61 2.7a6 6 0 003-2.65L15.6 14.1l-1.7 1.7h-.23a5 5 0 01-3.74-1.66 5.52 5.52 0 01-1.78-3.86v-.23L9.9 8.4z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Phone.displayName = 'Phone';
export default Phone;
