import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Calendar: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.5 5.25h-2.75V4h-1.5v1.25h-6.5V4h-1.5v1.25H4.5A1.25 1.25 0 003.25 6.5v12a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25v-12a1.25 1.25 0 00-1.25-1.25zm-.25 13H4.75V6.75h2.5V8h1.5V6.75h6.5V8h1.5V6.75h2.5zM9 11a1 1 0 11-1-1 1 1 0 011 1zm4 0a1 1 0 11-1-1 1 1 0 011 1zm-4 4a1 1 0 11-1-1 1 1 0 011 1zm4 0a1 1 0 11-1-1 1 1 0 011 1zm4-4a1 1 0 11-1-1 1 1 0 011 1z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Calendar.displayName = 'Calendar';
export default Calendar;
