import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Briefcase: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.5 6.25h-3.1l-1.63-2.44a1.24 1.24 0 00-1-.56h-3.5a1.24 1.24 0 00-1 .56L7.6 6.25H4.5A1.25 1.25 0 003.25 7.5v10a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25v-10a1.25 1.25 0 00-1.25-1.25zm-9.1-1.5h3.2l1 1.5H9.4zm-3.8 3h12.65v2.78a16.28 16.28 0 01-6.5 1.7V11h-1.5v1.22a16.45 16.45 0 01-6.5-1.68V7.75zm-1.85 9.5v-5.06a17.7 17.7 0 006.5 1.53V15h1.5v-1.27a17.71 17.71 0 006.5-1.53v5.05z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Briefcase.displayName = 'Briefcase';
export default Briefcase;
