import React from 'react';
import cx from 'classnames';

import styles from './ModalBody.module.scss';
import { IconButton } from '../IconButton';
import { Button } from '../Button';
import { InlineLink } from '../InlineLink';
import { LoadingSpinner } from '../LoadingSpinner';
import { CloseS1 } from '../Icons';

interface Props {
  body?: string;
  bodyClassName?: Parameters<typeof cx>[0];
  cancelText?: string;
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  closeTimeoutMS?: number;
  confirmText?: string;
  header?: string;
  img?: string;
  imgClassName?: string;
  isLoading?: boolean;
  onClickCancel?: () => void;
  onClickConfirm?: (arg: any) => void;
}

export const ModalBody: React.FC<Props> = props => (
  <div className={cx(styles.modalbody, props.className)}>
    <div className={styles.close}>
      <IconButton
        accessibilityLabel='Navigation'
        icon={<CloseS1 />}
        onClick={props.onClickCancel}
      />
    </div>
    <div className={cx(props.isLoading && styles.loading)}>
      {props.isLoading ? (
        <LoadingSpinner />
      ) : (
        <header className={styles.header}>
          {props.img ? (
            <img
              className={cx(styles.img, props.imgClassName)}
              src={props.img}
              alt=''
            />
          ) : (
            <></>
          )}
          <h4 className={styles.heading}>{props.header}</h4>
        </header>
      )}

      {props.body != null && (
        <p className={cx(styles.body, styles.bodyText, props.bodyClassName)}>
          {props.body}
        </p>
      )}

      {props.children != null && (
        <div className={cx(styles.body, props.bodyClassName)}>
          {props.children}
        </div>
      )}

      {props.confirmText != null ? (
        <Button wide className={styles.confirm} onClick={props.onClickConfirm}>
          {props.confirmText}
        </Button>
      ) : null}
      {props.cancelText != null ? (
        <div className={styles.cancel}>
          <InlineLink onClick={props.onClickCancel}>
            {props.cancelText ? props.cancelText : 'Cancel'}
          </InlineLink>
        </div>
      ) : null}
    </div>
  </div>
);

ModalBody.displayName = 'ModalBody';
export default ModalBody;
export { styles };
