import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const AddCircle: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12.75 11.25H16v1.5h-3.25V16h-1.5v-3.25H8v-1.5h3.25V8h1.5zm8 .75A8.75 8.75 0 1112 3.25 8.76 8.76 0 0120.75 12zm-1.5 0A7.25 7.25 0 1012 19.25 7.26 7.26 0 0019.25 12z'
        fill='currentColor'
      />
    </svg>
  </i>
);

AddCircle.displayName = 'AddCircle';
export default AddCircle;
export { styles };
