import React from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import styles from './Modal.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: Parameters<typeof cx>[0];
  isOpen: boolean;
  onRequestClose: () => void;
}

export const Modal: React.FC<Props> = props => {
  return (
    <ReactModal
      bodyOpenClassName={styles.reactBody}
      ariaHideApp={false}
      className={{
        base: cx(styles.modalPortal, styles.modalPortalContent),
        afterOpen: styles.modalPortalContentAfterOpen,
        beforeClose: styles.modalPortalContentBeforeClose,
      }}
      closeTimeoutMS={800}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      portalClassName={cx(styles.modalPortal, props.className)}
      overlayClassName={{
        base: styles.modalPortalOverlay,
        afterOpen: styles.modalPortalOverlayAfterOpen,
        beforeClose: styles.modalPortalOverlayBeforeClose,
      }}
      shouldCloseOnOverlayClick={true}
    >
      {props.children}
    </ReactModal>
  );
};

Modal.displayName = 'Modal';
export default Modal;
export { styles };
