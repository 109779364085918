import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Institution: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M18.75 17.25v-6.58a1.28 1.28 0 00.6-.45l1.26-1.79a.74.74 0 00.12-.61.8.8 0 00-.39-.49l-7.7-3.89a1.19 1.19 0 00-1.28 0l-7.7 3.89a.8.8 0 00-.39.49.74.74 0 00.12.61l1.26 1.79a1.28 1.28 0 00.6.45v6.58H3v1.5h18v-1.5zm-5.5-6.5v6.5h-2.5v-6.5zm1.5 0h2.5v6.5h-2.5zM12 4.8l6.88 3.48-.68 1H5.8l-.68-1zm-5.25 6h2.5v6.5h-2.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Institution.displayName = 'Institution';
export default Institution;
