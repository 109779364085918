import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductIdentity: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinejoin='round'
        strokeWidth='1.5'
        data-name='Layer 2'
      >
        <circle cx={12} cy={12} r={8} />
        <path d='M12 15a2 2 0 01-2-2h0a2 2 0 014 0h0a2 2 0 01-2 2zM16 19v-.57c0-1.61-.56-2.39-2.3-2.43h-3.4c-1.74 0-2.3.82-2.3 2.43V19M9.98 7.89l1.56 1.2 2.93-2.58' />
      </g>
    </svg>
  </i>
);

ProductIdentity.displayName = 'ProductIdentity';
export default ProductIdentity;
