import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductAssets: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <circle
        cx={12}
        cy={12}
        r={8}
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 10.9L12 7.4l-4.5 3.5v4.6h3.4v-1.3c-.1-.6.4-1.2 1-1.2 0 0 0 0 0 0h.1c.6 0 1.1.5 1.1 1.1v1.4h3.4v-4.6z'
        fill='none'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeMiterlimit={10}
      />
    </svg>
  </i>
);

ProductAssets.displayName = 'ProductAssets';
export default ProductAssets;
