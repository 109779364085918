import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Hand: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M18.75 13v6h-1.5v-6A1.25 1.25 0 0016 11.75h-.25V14h-1.5v-2.25h-1.5V14h-1.5V6.5a.75.75 0 00-1.5 0v10h-1.5v-2.75H7.5a.76.76 0 00-.75.75V19h-1.5v-4.5a2.25 2.25 0 012.25-2.25h.75V6.5a2.25 2.25 0 014.5 0v3.75H16A2.75 2.75 0 0118.75 13z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Hand.displayName = 'Hand';
export default Hand;
