import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const AlertBell: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M20.11 15.36l-1.05-.64c-.19-.14-.3-.32-.3-.9V10a6.75 6.75 0 00-13.5 0v3.82c0 .6-.15.78-.25.85l-1.1.69a.75.75 0 00.4 1.39h4V17a3.75 3.75 0 007.5 0v-.25h4a.75.75 0 00.4-1.39zM14.25 17a2.25 2.25 0 01-4.5 0 2 2 0 010-.25h4.46a2 2 0 01.04.25zm-7.82-1.75a3 3 0 00.31-1.43V10a5.25 5.25 0 0110.5 0v3.82a2.83 2.83 0 00.31 1.43z'
        fill='currentColor'
      />
    </svg>
  </i>
);

AlertBell.displayName = 'AlertBell';
export default AlertBell;
export { styles };
