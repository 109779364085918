import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Folder: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.5 7.25h-7.19L9.53 4.47A.75.75 0 009 4.25H4.5A1.25 1.25 0 003.25 5.5v12a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25v-9a1.25 1.25 0 00-1.25-1.25zM8.69 5.75l1.5 1.5H4.75v-1.5zm10.56 11.5H4.75v-8.5h14.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Folder.displayName = 'Folder';
export default Folder;
