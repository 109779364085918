import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const CloseS2: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M16.03 9.03l-1.06-1.06L12 10.94 9.03 7.97 7.97 9.03 10.94 12l-2.97 2.97 1.06 1.06L12 13.06l2.97 2.97 1.06-1.06L13.06 12l2.97-2.97z'
      />
    </svg>
  </i>
);

CloseS2.displayName = 'CloseS2';
export default CloseS2;
