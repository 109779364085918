import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Activity: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 17.38a.76.76 0 01-.7-.55L8.85 8.22 7.19 12a1.22 1.22 0 01-1.14.75H3v-1.5h2.88L8.31 5.7a.75.75 0 01.74-.45.74.74 0 01.67.55l2.35 8.33 2.22-6.38a.76.76 0 01.66-.5.77.77 0 01.72.41l1.85 3.59H21v1.5h-3.63a1.24 1.24 0 01-1.11-.68L15.13 9.9l-2.42 7a.76.76 0 01-.71.48zm-6.18-6z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Activity.displayName = 'Activity';
export default Activity;
export { styles };
