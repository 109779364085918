import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Configuration: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M17.75 7.25V6.5c0-.69-.56-1.25-1.25-1.25h-4c-.69 0-1.25.56-1.25 1.25v.75H3v1.5h8.25v.75c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25v-.75H21v-1.5h-3.25zm-1.5 2h-3.5v-2.5h3.5v2.5zm-3.5 5.25c0-.69-.56-1.25-1.25-1.25h-4a1.25 1.25 0 00-1.25 1.25v.75H3v1.5h3.25v.75c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25v-.75H21v-1.5h-8.25v-.75zm-1.5 2.75h-3.5v-2.5h3.5v2.5z'
        />
      </g>
    </svg>
  </i>
);

Configuration.displayName = 'Configuration';
export default Configuration;
