import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Switch: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M9.54 10.53L8.48 9.47 12 5.94l3.53 3.53-1.06 1.06L12 8.06zM12 15.94l-2.47-2.47-1.06 1.06L12 18.06l3.53-3.53-1.06-1.06z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Switch.displayName = 'Switch';
export default Switch;
