import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Search: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M13.5 4.25a6.25 6.25 0 00-6.25 6.25 6.18 6.18 0 001.34 3.85l-4.12 4.12 1.06 1.06 4.12-4.12a6.18 6.18 0 003.85 1.34 6.25 6.25 0 000-12.5zm0 11a4.75 4.75 0 114.75-4.75 4.75 4.75 0 01-4.75 4.75z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Search.displayName = 'Search';
export default Search;
