import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Tweet: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M21 6l-2.92.73A3.56 3.56 0 0011.73 8 7.39 7.39 0 019 7.39a9 9 0 01-2.61-1.92.74.74 0 00-1.24.32 6.47 6.47 0 00.26 4.08H4a.74.74 0 00-.61.31.73.73 0 00-.1.67 6.8 6.8 0 003.87 3.75 9.39 9.39 0 01-2.47 1.29.75.75 0 00-.17 1.33 9.16 9.16 0 0014.23-7.53V9zm-3.75 3.69a7.61 7.61 0 01-7.65 7.56 7.71 7.71 0 01-2.94-.58 10.38 10.38 0 002.55-1.85.74.74 0 00.18-.72.75.75 0 00-.54-.52 6.26 6.26 0 01-3.57-2.21H6.8a.76.76 0 00.55-1.27 3.77 3.77 0 01-.92-2.58 8.63 8.63 0 002 1.24 9.41 9.41 0 004 .76.76.76 0 00.75-.75 2.05 2.05 0 014.1 0z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Tweet.displayName = 'Tweet';
export default Tweet;
