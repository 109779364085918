import React from 'react';
import cx from 'classnames';

import Touchable, { TouchableProps } from '../Touchable';

import styles from './InlineLink.module.scss';

export const InlineLink: React.FC<TouchableProps> = ({
  children,
  className,
  ...restProps
}) => (
  <Touchable {...restProps} className={cx(styles.inlinelink, className)}>
    {children}
  </Touchable>
);

InlineLink.displayName = 'InlineLink';
export default InlineLink;
export { styles };
