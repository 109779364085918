import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Tag: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M18.5 4.25h-6.22a1.23 1.23 0 00-.88.37l-7.58 7.57a1.27 1.27 0 000 1.77L10 20.18a1.24 1.24 0 00.88.36 1.28 1.28 0 00.89-.36l7.57-7.58a1.23 1.23 0 00.37-.88V5.5a1.25 1.25 0 00-1.21-1.25zm-.25 7.36l-7.33 7.33-5.86-5.86 7.33-7.33h5.86zM15 8a1 1 0 111 1 1 1 0 01-1-1zm-3.14.8l3.34 3.34-1.06 1.06-3.34-3.34zm-1.57 2.43l2.48 2.48-1.06 1.06-2.48-2.48z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Tag.displayName = 'Tag';
export default Tag;
