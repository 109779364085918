import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductAuth: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 3.25A8.75 8.75 0 1020.75 12 8.76 8.76 0 0012 3.25zm0 16a7.26 7.26 0 01-7.21-6.5h8.4l-2.72 2.72 1.06 1.06L16.06 12l-4.53-4.52-1.06 1.06 2.72 2.71h-8.4a7.25 7.25 0 117.21 8z'
      />
    </svg>
  </i>
);

ProductAuth.displayName = 'ProductAuth';
export default ProductAuth;
