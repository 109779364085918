import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Build: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M19.36 4.25H4.64a1.39 1.39 0 00-1.39 1.39v11.72a1.39 1.39 0 001.39 1.39h1.88v-1.5H4.75v-8.5h14.5v8.5h-1.77v1.5h1.88a1.39 1.39 0 001.39-1.39V5.64a1.39 1.39 0 00-1.39-1.39zM4.75 5.75h1.5v1.5h-1.5zm3 1.5v-1.5h11.5v1.5zm9 7.75a4.76 4.76 0 01-2 3.85V21h-1.5v-3l.37-.21a3.21 3.21 0 00.78-5l-.59 2.92h-3.62l-.59-2.88a3.21 3.21 0 00.78 5l.37.21v3h-1.5v-2.19a4.71 4.71 0 01.49-8l.9-.49.77 3.9h1.18l.77-3.9.9.49A4.74 4.74 0 0116.75 15z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

Build.displayName = 'Build';
export default Build;
