import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ItemManagement: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M18.5 5.25h-2.8a1.23 1.23 0 00-1.2-1h-5a1.23 1.23 0 00-1.2 1H5.5A1.25 1.25 0 004.25 6.5v12a1.25 1.25 0 001.25 1.25h13a1.25 1.25 0 001.25-1.25v-12a1.25 1.25 0 00-1.25-1.25zm-8.75.5h4.5v1.5h-4.5zm8.5 12.5H5.75V6.75h2.5v.75A1.25 1.25 0 009.5 8.75h5a1.25 1.25 0 001.25-1.25v-.75h2.5zM14.47 10l1.06 1L11 15.56 8.47 13l1.06-1L11 13.44z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

ItemManagement.displayName = 'ItemManagement';
export default ItemManagement;
