import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Categories: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 12.54a1.24 1.24 0 01-.88-.36l-3.3-3.3a1.26 1.26 0 010-1.76l3.3-3.3a1.24 1.24 0 011.76 0l3.3 3.3a1.26 1.26 0 010 1.76l-3.3 3.3a1.24 1.24 0 01-.88.36zM9.06 8L12 10.94 14.94 8 12 5.06zm11.08 11.75h-6.28a1.24 1.24 0 01-1.08-.62 1.22 1.22 0 010-1.25l3.13-5.49a1.31 1.31 0 012.18 0l3.13 5.49a1.22 1.22 0 010 1.25 1.24 1.24 0 01-1.08.62zm-6.06-1.13zm.21-.37h5.42L17 13.51zM7 19.75A3.75 3.75 0 1110.75 16 3.75 3.75 0 017 19.75zm0-6A2.25 2.25 0 109.25 16 2.25 2.25 0 007 13.75z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Categories.displayName = 'Categories';
export default Categories;
