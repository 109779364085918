import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Transfer: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M17.19 8.75H6v-1.5h11.19L15 5l1-1 4 4-4 4-1-1zM9 13l-1-1-4 4 4 4 1-1-2.19-2.25H17v-1.5H6.81z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Transfer.displayName = 'Transfer';
export default Transfer;
