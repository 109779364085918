import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Home: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M21 12l-8.09-8.17a1.29 1.29 0 00-1.82 0L3 12l1 1 1.1-1.1v6.6a1.25 1.25 0 001.25 1.25H17.5a1.25 1.25 0 001.25-1.25v-6.7L20 13zm-10.25 6.25V16a1.25 1.25 0 012.5 0v2.25zm6.5 0h-2.5V16a2.75 2.75 0 00-5.5 0v2.25H6.62v-7.82L12 5.05l5.25 5.25z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Home.displayName = 'Home';
export default Home;
