import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const SidebarShow: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg id='Icons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        fill='currentColor'
        d='M5 6.25h7v1.5H5zM5 11.25h7v1.5H5zM5 16.25h13v1.5H5zM15.53 12.53L19.06 9l-3.53-3.53-1.06 1.06L16.94 9l-2.47 2.47 1.06 1.06z'
      />
      <path id='icon-container' fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

SidebarShow.displayName = 'SidebarShow';
export default SidebarShow;
