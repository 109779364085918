import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Message: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.3 7.17A8.75 8.75 0 105.22 17.53l-2.65 1.86A.74.74 0 003 20.75h9a8.75 8.75 0 007.3-13.58zM18 16a7.22 7.22 0 01-6 3.23H5.38l.93-.65a1.3 1.3 0 00.52-.86 1.27 1.27 0 00-.31-1A7.25 7.25 0 1118.06 8a7.19 7.19 0 01-.06 8zm-9-4a1 1 0 11-1-1 1 1 0 011 1zm4 0a1 1 0 11-1-1 1 1 0 011 1zm4 0a1 1 0 11-1-1 1 1 0 011 1z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Message.displayName = 'Message';
export default Message;
