import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Video: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M1 0h24v24H1z' />
      <path
        d='M22.73 7.68a1.23 1.23 0 00-1.13-.17L17.25 9V6A1.25 1.25 0 0016 4.75H5.5A1.25 1.25 0 004.25 6v12a1.25 1.25 0 001.25 1.25H16A1.25 1.25 0 0017.25 18v-3l4.35 1.45a1.12 1.12 0 00.4.07 1.21 1.21 0 00.73-.24 1.23 1.23 0 00.52-1V8.69a1.23 1.23 0 00-.52-1.01zm-7 10.07h-10V6.25h10zm6-2.79l-4.5-1.5v-2.92L21.75 9zM9 16a1 1 0 10-1-1 1 1 0 001 1zm3 0a1 1 0 10-1-1 1 1 0 001 1z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Video.displayName = 'Video';
export default Video;
