import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Edit: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M20.18 6.12l-2.3-2.3a1.26 1.26 0 00-1.76 0L5.47 14.47a.82.82 0 00-.2.35l-1 4a.75.75 0 00.73.93h.18l4-1a.82.82 0 00.35-.2L20.18 7.88a1.26 1.26 0 000-1.76zM8.62 17.32L6 18l.65-2.59 7.85-7.85 1.94 1.94zm8.88-8.88L15.56 6.5 17 5.06 18.94 7z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Edit.displayName = 'Edit';
export default Edit;
