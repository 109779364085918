import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Database: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M20.75 6.5a1.25 1.25 0 00-1.25-1.25h-15A1.25 1.25 0 003.25 6.5V11a1.73 1.73 0 00.32 1 1.73 1.73 0 00-.32 1v4.5a1.25 1.25 0 001.25 1.25h15a1.25 1.25 0 001.25-1.25V13a1.73 1.73 0 00-.32-1 1.73 1.73 0 00.32-1zm-16 .25h14.5V11a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25zm14.5 10.5H4.75V13a.25.25 0 01.25-.25h14a.25.25 0 01.25.25zM6 9a1 1 0 111 1 1 1 0 01-1-1zm2 6a1 1 0 11-1-1 1 1 0 011 1zm9-5.25h-5v-1.5h5zm-5 4.5h5v1.5h-5z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

Database.displayName = 'Database';
export default Database;
