import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Trash: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19 6.25h-3.78a3.23 3.23 0 00-6.44 0H5v1.5h1.34l1.37 10.91A1.26 1.26 0 009 19.75h6.1a1.26 1.26 0 001.24-1.09l1.32-10.91H19zm-7-1.5a1.74 1.74 0 011.72 1.5h-3.44A1.74 1.74 0 0112 4.75zm2.83 13.5H9.17L7.85 7.75h8.3z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Trash.displayName = 'Trash';
export default Trash;
