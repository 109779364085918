import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Key: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M20.53 16.47l-6.91-6.91a4.45 4.45 0 00.13-1.06 5.25 5.25 0 10-5.25 5.25 4.45 4.45 0 001.06-.13l1.69 1.69V17a.76.76 0 00.75.75h1.25V19a.76.76 0 00.75.75h1.69l.78.78c.14.14.331.22.53.22h3a.76.76 0 00.75-.75v-3a.75.75 0 00-.22-.53zm-1.28 2.78h-1.94l-.78-.78a.75.75 0 00-.53-.22h-1.25V17a.76.76 0 00-.75-.75h-1.25V15a.75.75 0 00-.22-.53l-2.21-2.21a.75.75 0 00-.75-.19 3.68 3.68 0 01-1.07.18 3.75 3.75 0 113.75-3.75 3.68 3.68 0 01-.18 1.07.75.75 0 00.19.75l7 7-.01 1.93zM9 8a1 1 0 11-2 0 1 1 0 012 0z'
        />
      </g>
    </svg>
  </i>
);

Key.displayName = 'Key';
export default Key;
