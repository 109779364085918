import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Lightning: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg'>
      <path
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinejoin='round'
        d='M6 14l8-10-1 6h5l-8 10 1-6z'
        fill='none'
        fillRule='evenodd'
      />
    </svg>
  </i>
);

Lightning.displayName = 'Lightning';
export default Lightning;
