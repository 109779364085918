import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ChevronS2Left: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M12.45 15.52l-3.53-3.53 3.53-3.53 1.06 1.06-2.47 2.47 2.47 2.47z'
        />
      </g>
    </svg>
  </i>
);

ChevronS2Left.displayName = 'ChevronS2Left';
export default ChevronS2Left;
