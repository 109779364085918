import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Bookmark: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M7.5 20.75a1.27 1.27 0 01-.62-.16 1.25 1.25 0 01-.63-1.09v-14A1.25 1.25 0 017.5 4.25h9a1.25 1.25 0 011.25 1.25v14a1.24 1.24 0 01-1.89 1.07L12 18.26l-3.86 2.31a1.21 1.21 0 01-.64.18zm4.5-4a1.2 1.2 0 01.65.19l3.6 2.16V5.75h-8.5v13.31l3.61-2.17a1.21 1.21 0 01.64-.18zm-.13 1.47zm.26 0z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

Bookmark.displayName = 'Bookmark';
export default Bookmark;
