import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Information: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12.75 16h-1.5v-5h1.5zM12 8a1 1 0 101 1 1 1 0 00-1-1zm8.75 4A8.75 8.75 0 1012 20.75 8.77 8.77 0 0020.75 12zm-1.5 0A7.25 7.25 0 1112 4.75 7.26 7.26 0 0119.25 12z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Information.displayName = 'Information';
export default Information;
