import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const User: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 11.75a3.75 3.75 0 003.75-3.69V8a3.75 3.75 0 00-7.5-.06V8A3.75 3.75 0 0012 11.75zM9.75 8a2.25 2.25 0 014.5 0 2.25 2.25 0 01-4.5 0zm10 8v3h-1.5v-3c0-1.21-.24-2.14-3.18-2.21l-2.52 2.71a.77.77 0 01-1.1 0l-2.52-2.75c-2.94.07-3.18 1-3.18 2.21v3h-1.5V16c0-3.22 2.54-3.67 4.76-3.71a1.33 1.33 0 011 .41l2 2.22 2-2.22a1.28 1.28 0 011-.41c2.2 0 4.74.45 4.74 3.71z'
        fill='currentColor'
      />
    </svg>
  </i>
);

User.displayName = 'User';
export default User;
