import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const PieChart: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.7 11.11a8.69 8.69 0 01.05.89 7.75 7.75 0 11-10-7.43L10.21 6a6.25 6.25 0 108 6v-.73zm.35-2a.8.8 0 01-.4.41l-7.35 3.17a.8.8 0 01-.3.06.75.75 0 01-.41-.12.77.77 0 01-.34-.63V4a.76.76 0 01.75-.75 8.77 8.77 0 018 5.29.78.78 0 01.05.58zm-1.72-.66a7.26 7.26 0 00-5.58-3.67v6.07z'
        fill='currentColor'
      />
    </svg>
  </i>
);

PieChart.displayName = 'PieChart';
export default PieChart;
