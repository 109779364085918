import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Libraries: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M20.63 16L16.46 5.87a1.26 1.26 0 00-.68-.68 1.21 1.21 0 00-.95 0l-2.17.88a1.24 1.24 0 00-1.16-.82h-3a1.18 1.18 0 00-.5.11 1.18 1.18 0 00-.5-.11h-3A1.25 1.25 0 003.25 6.5v11a1.25 1.25 0 001.25 1.25h3a1.18 1.18 0 00.5-.11 1.18 1.18 0 00.5.11h3a1.25 1.25 0 001.25-1.25v-6.18l2.79 6.81a1.26 1.26 0 00.68.68 1.15 1.15 0 00.48.1 1.1 1.1 0 00.47-.1L20 17.68a1.26 1.26 0 00.68-.68 1.23 1.23 0 00-.05-1zM4.75 6.75h2.5v10.5h-2.5zm6.5 10.5h-2.5V6.75h2.5zm5.59.08l-4-9.71 2.31-1 4 9.71z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Libraries.displayName = 'Libraries';
export default Libraries;
