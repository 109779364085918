import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Overview: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M16.6 8.25h-3.2a1.16 1.16 0 00-1.15 1.15v3.2a1.16 1.16 0 001.15 1.15h3.2a1.16 1.16 0 001.15-1.15V9.4a1.16 1.16 0 00-1.15-1.15zm-.35 4h-2.5v-2.5h2.5zm-3.25-9A7.76 7.76 0 005.25 11 7.67 7.67 0 007 15.92l-3.53 3.55 1.06 1.06L8.08 17A7.67 7.67 0 0013 18.75a7.75 7.75 0 000-15.5zM6.75 11a5.7 5.7 0 01.13-1.25h2.37v2.5H6.88A5.7 5.7 0 016.75 11zM13 17.25a6.24 6.24 0 01-5.6-3.5h2.2a1.16 1.16 0 001.15-1.15V9.4A1.16 1.16 0 009.6 8.25H7.4a6.25 6.25 0 115.6 9z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Overview.displayName = 'Overview';
export default Overview;
