import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Docs: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M22.53 17.47l-2.4-2.41a3.74 3.74 0 00-2.38-5.73V5a.77.77 0 00-.31-.61.75.75 0 00-.68-.1L11 6.22 5.24 4.29a.75.75 0 00-.68.1.77.77 0 00-.31.61v10a.75.75 0 00.51.71l5.86 2a1.23 1.23 0 00.76 0l3.93-1.31a3.67 3.67 0 001.71.43 3.73 3.73 0 002.06-.62l2.41 2.4zm-6.28-8.14A3.73 3.73 0 0014 15.22l-2.25.78V7.55L16.25 6zM5.75 6l4.5 1.51V16l-4.5-1.5zm9 7A2.25 2.25 0 1117 15.25 2.25 2.25 0 0114.75 13z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Docs.displayName = 'Docs';
export default Docs;
