import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductTransactions: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg id='Icons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M12 3.25A8.75 8.75 0 1020.75 12 8.76 8.76 0 0012 3.25zm4.25 2.89v7.92l-3.6-2.16a1.25 1.25 0 00-1.29 0l-3.61 2.16V6.14a7.19 7.19 0 018.5 0zM12 19.25A7.24 7.24 0 016.25 7.6v6.9a1.25 1.25 0 00.63 1.09 1.27 1.27 0 00.62.16 1.21 1.21 0 00.64-.18L12 13.26l3.86 2.31a1.24 1.24 0 001.89-1.07V7.6A7.24 7.24 0 0112 19.25z'
      />
      <path fill='currentColor' d='M9 9.25h6v1.5H9zM9 6.25h6v1.5H9z' />
    </svg>
  </i>
);

ProductTransactions.displayName = 'ProductTransactions';
export default ProductTransactions;
