import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const SidebarHide: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg id='Icons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path id='icon-container' fill='none' d='M0 0h24v24H0z' />
      <path
        fill='currentColor'
        d='M5 6.25h7v1.5H5zM5 11.25h7v1.5H5zM5 16.25h13v1.5H5zM18.53 6.53l-1.06-1.06L13.94 9l3.53 3.53 1.06-1.06L16.06 9l2.47-2.47z'
      />
    </svg>
  </i>
);

SidebarHide.displayName = 'SidebarHide';
export default SidebarHide;
