import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Help: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M17.94 5.51A8.75 8.75 0 004.88 17L2.4 19.47a.75.75 0 00.53 1.28h8.86a8.89 8.89 0 009-8.33 8.7 8.7 0 00-2.85-6.91zm-6.15 13.74h-7l1.31-1.31a1.33 1.33 0 00.13-1.72 7.25 7.25 0 1113.13-3.87 7.47 7.47 0 01-7.57 6.9zm3-9.87a2.92 2.92 0 01-1.59 3.39.91.91 0 00-.46.83v.4h-1.5v-.4a2.38 2.38 0 011.33-2.19 1.4 1.4 0 00.76-1.65 1.35 1.35 0 00-1-1 1.39 1.39 0 00-1.77 1.34H9.11a2.9 2.9 0 015.69-.76zM13 16a1 1 0 11-1-1 1 1 0 011 1z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Help.displayName = 'Help';
export default Help;
