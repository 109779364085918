import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const AlertError: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12.75 14h-1.5V9h1.5zM12 15a1 1 0 101 1 1 1 0 00-1-1zm9.13 3.87a1.72 1.72 0 01-1.52.88H4.39a1.75 1.75 0 01-1.51-2.64l7.61-13a1.75 1.75 0 013 0l7.61 13a1.71 1.71 0 01.03 1.76zm-1.3-1l-7.61-13a.27.27 0 00-.22-.12.26.26 0 00-.22.13l-7.61 13a.24.24 0 000 .25.24.24 0 00.22.13h15.22a.24.24 0 00.22-.13.24.24 0 000-.26z'
        fill='currentColor'
      />
    </svg>
  </i>
);

AlertError.displayName = 'AlertError';
export default AlertError;
export { styles };
