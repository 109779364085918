import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ChevronS1Up: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M16.47 14.53L12 10.06l-4.47 4.47-1.06-1.06L12 7.94l5.53 5.53z'
        />
      </g>
    </svg>
  </i>
);

ChevronS1Up.displayName = 'ChevronS1Up';
export default ChevronS1Up;
