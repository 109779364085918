import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const GraduationCap: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M21.38 9.32l-8.82-3.95a1.37 1.37 0 00-1.12 0l-8.82 4a.75.75 0 000 1.36l3.63 1.63v4.62a.74.74 0 00.09.36c.06.1 1.41 2.46 5.66 2.46a7.72 7.72 0 004.38-1.16 4.27 4.27 0 001.28-1.3.74.74 0 00.09-.36v-4.67l1.5-.67v3.72A1 1 0 0019 16a1 1 0 002 0 1 1 0 00-.25-.64V11h-.07l.7-.32a.75.75 0 000-1.36zm-5.13 7.38a3.84 3.84 0 01-.72.65 6.19 6.19 0 01-3.53.9c-2.79 0-3.94-1.15-4.25-1.55V13l3.69 1.65a1.37 1.37 0 001.12 0L16.25 13zM12 13.24L4.76 10 12 6.76 19.24 10z'
        fill='currentColor'
      />
    </svg>
  </i>
);

GraduationCap.displayName = 'GraduationCap';
export default GraduationCap;
