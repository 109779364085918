import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Rotate: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 4.25a7.72 7.72 0 00-6.25 3.18V4h-1.5v5.75H10v-1.5H7a6.24 6.24 0 11.57 8.17l-1.05 1.06A7.75 7.75 0 1012 4.25z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Rotate.displayName = 'Rotate';
export default Rotate;
