import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Document: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.53 7.47l-4-4a.75.75 0 00-.53-.22H6.5A1.25 1.25 0 005.25 4.5v15a1.25 1.25 0 001.25 1.25h12a1.25 1.25 0 001.25-1.25V8a.75.75 0 00-.22-.53zm-1.34.78h-3.44V4.81zm-11.44 11V4.75h6.5V9a.76.76 0 00.75.75h4.25v9.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Document.displayName = 'Document';
export default Document;
