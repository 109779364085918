import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Globe: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 3.25A8.75 8.75 0 1020.75 12 8.76 8.76 0 0012 3.25zM7 6.78a8.77 8.77 0 012.69.89l.17.1-.22.15C7.65 9.09 6.67 9.68 7.33 11c.39.78 1.33.79 2.15.79 1.49 0 1.77.15 1.77 1a11.92 11.92 0 01-.62 3.11c-.69-1-1.83-2.63-3.63-2.63-2.13 0-2.24-1.14-2.25-1.25A7.23 7.23 0 017 6.78zm-1.81 7.68a5.2 5.2 0 001.81.29c1.06 0 1.9 1.24 2.45 2.06l.27.39a1.24 1.24 0 001 .51h.21a1.27 1.27 0 001-.88l.06-.2a14.45 14.45 0 00.77-3.84c0-2.48-2.2-2.48-3.26-2.48a6 6 0 01-.75 0c.3-.25 1-.69 1.64-1.06a1.73 1.73 0 001.07-1.5c0-.55-.39-1-1.12-1.37a11.93 11.93 0 00-1.76-.72A7.25 7.25 0 0118.7 9.25H17a2.73 2.73 0 00-2 .82 2.7 2.7 0 00-.79 2 3.89 3.89 0 000 .39c.06 1 .89 1.5 1.62 2 1.13.73 1.87 1.23 1.06 2.87a7.22 7.22 0 01-11.7-2.87zm13.4.55a4.66 4.66 0 00-1.87-1.87c-.42-.27-.93-.61-.95-.83V12a1.21 1.21 0 01.36-.89 1.22 1.22 0 01.89-.37h2.11a6.46 6.46 0 01.12 1.26 7.24 7.24 0 01-.66 3z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Globe.displayName = 'Globe';
export default Globe;
