import React, { FunctionComponent, ReactNode } from 'react';
import cx from 'classnames';
import Touchable from '../Touchable';

import styles from './IconButton.module.scss';

export interface IconButtonProps {
  // accessibilityLabel should provide meaningful context about button to those
  // who rely on a screen reader to interact with the UI
  accessibilityLabel: string;
  className?: Parameters<typeof cx>[0];
  icon: ReactNode;
  onClick?: () => void;
  href?: string;
  // to?: string;
}

export const IconButton: FunctionComponent<IconButtonProps> = ({
  accessibilityLabel,
  className,
  icon,
  onClick,
  href,
  // to,
}) => (
  <Touchable
    ariaLabel={accessibilityLabel}
    className={cx(styles.container, className)}
    onClick={onClick}
    href={href}
    // to={to}
  >
    {icon}
  </Touchable>
);

IconButton.displayName = 'IconButton';
export default IconButton;
export { styles };
