import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Mobile: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M16.5 3.25h-9A1.25 1.25 0 006.25 4.5v15a1.25 1.25 0 001.25 1.25h9a1.25 1.25 0 001.25-1.25v-15a1.25 1.25 0 00-1.25-1.25zm-.25 16h-8.5V4.75h8.5zM14 7.75h-4v-1.5h4zM13 17a1 1 0 11-1-1 1 1 0 011 1z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Mobile.displayName = 'Mobile';
export default Mobile;
