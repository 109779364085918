import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Rocket: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M19.7 4.72a.79.79 0 00-.42-.42 5.6 5.6 0 00-5.81 1.17l-2.76 2.76-4.24-.3a2.76 2.76 0 00-2.26.8l-.74.74a.75.75 0 000 1.06l2.67 2.67.62.62a2.6 2.6 0 00-1.29.65C3.71 16.23 4.24 19 4.26 19.15l.1.49.49.1a7.72 7.72 0 001.15.09 5 5 0 003.56-1.3 2.6 2.6 0 00.65-1.29l.29.29 3 3a.75.75 0 001.06 0l.74-.74a2.73 2.73 0 00.8-2.23l-.3-4.27 2.76-2.76a5.6 5.6 0 001.14-5.81zM5.27 9.79a1.32 1.32 0 011.07-.36l3 .2L7 11.94 5.06 10zm3.2 7.68a3.71 3.71 0 01-2.79.85 3.71 3.71 0 01.85-2.79 1.72 1.72 0 011.94 0 1.72 1.72 0 010 1.94zm5.74 1.26l-.21.21L12.06 17l2.31-2.31.21 3a1.3 1.3 0 01-.37 1.04zM11 15.94L8.06 13l6.47-6.47a4 4 0 013.87-.93 4 4 0 01-.93 3.87zM17 8a1 1 0 11-1-1 1 1 0 011 1z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Rocket.displayName = 'Rocket';
export default Rocket;
