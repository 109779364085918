import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Webhooks: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 3.25A8.75 8.75 0 1020.75 12 8.76 8.76 0 0012 3.25zm6.19 5h-2.78a11.54 11.54 0 00-1-3.07 7.26 7.26 0 013.78 3.07zM19.25 12a7.26 7.26 0 01-.36 2.25h-3.26a20.92 20.92 0 00.12-2.25 20.92 20.92 0 00-.12-2.25h3.26a7.26 7.26 0 01.36 2.25zM12 19.25c-.6 0-1.41-1.31-1.88-3.5h3.76c-.47 2.19-1.28 3.5-1.88 3.5zm-2.12-5a19.54 19.54 0 010-4.5h4.24a19.63 19.63 0 01.13 2.25 19.63 19.63 0 01-.13 2.25zM4.75 12a7.26 7.26 0 01.36-2.25h3.26a21.15 21.15 0 000 4.5H5.11A7.26 7.26 0 014.75 12zM12 4.75c.6 0 1.41 1.31 1.88 3.5h-3.76c.47-2.19 1.28-3.5 1.88-3.5zm-2.43.43a11.54 11.54 0 00-1 3.07H5.81a7.26 7.26 0 013.76-3.07zM5.81 15.75h2.78a11.54 11.54 0 001 3.07 7.26 7.26 0 01-3.78-3.07zm8.62 3.07a11.54 11.54 0 001-3.07h2.78a7.26 7.26 0 01-3.78 3.07z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Webhooks.displayName = 'Webhooks';
export default Webhooks;
