import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Guide: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M20.46 5.41a.77.77 0 00-.64-.14L12 7.22 4.18 5.27a.77.77 0 00-.64.14.75.75 0 00-.29.59v11a.76.76 0 00.57.73l7.84 1.95a1.27 1.27 0 00.34.05 1.24 1.24 0 00.33 0l7.85-2a.76.76 0 00.57-.73V6a.75.75 0 00-.29-.59zM4.75 7l6.5 1.62V18l-6.5-1.63zm14.5 9.45L12.75 18V8.58L19.25 7zm-5.07-4.68l-.36-1.46 4-1 .36 1.46zm3.64.54l.36 1.46-4 1-.36-1.46zm-7.29-.74l-3 3-2-2 1.06-1.06 1 1 2-2z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Guide.displayName = 'Guide';
export default Guide;
