import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Buildings: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M18.5 4.25h-7a1.25 1.25 0 00-1.25 1.25v3.75H6.5a1.25 1.25 0 00-1.25 1.25V19a.76.76 0 00.75.75h13a.76.76 0 00.75-.75V5.5a1.25 1.25 0 00-1.25-1.25zm-8.25 14h-3.5v-7.5h3.5zm8 0h-2.5V16h-1.5v2.25h-2.5V5.75h6.5zM17 9.75h-4v-1.5h4zm0 3h-4v-1.5h4z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Buildings.displayName = 'Buildings';
export default Buildings;
