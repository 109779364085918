import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Integrations: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M20.42 7.12l-1.24-1.24 1.59-1.59-1.06-1.06-1.59 1.59-1.24-1.24a2.25 2.25 0 00-3.18 0l-2.47 2.48a.74.74 0 000 1.06l.88.88-1.59 1.59 1.06 1.06 1.59-1.59 1.77 1.77-1.59 1.59 1.06 1.06L16 11.89l.88.88a.75.75 0 001.06 0l2.48-2.47a2.25 2.25 0 000-3.18zm-1.06 2.12l-1.95 1.94-4.59-4.59 1.94-1.95a.75.75 0 011.06 0l3.54 3.54a.75.75 0 010 1.06zm-11.24 1a.77.77 0 00-1.06 0L4.58 12.7a2.25 2.25 0 000 3.18l1.24 1.24-1.73 1.73 1.06 1.06 1.73-1.73 1.24 1.24a2.22 2.22 0 001.59.66 2.25 2.25 0 001.59-.66l2.47-2.48a.74.74 0 000-1.06zm2.12 8.13a.75.75 0 01-1.06 0l-3.54-3.55a.75.75 0 010-1.06l2-1.94 4.59 4.59z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

Integrations.displayName = 'Integrations';
export default Integrations;
