import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Shield: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 20.75a.75.75 0 01-.41-.12l-4.65-3.06a3.41 3.41 0 01-1.69-2.92V6.42a1.24 1.24 0 01.84-1.18l5.67-1.95a.79.79 0 01.48 0l5.67 2a1.24 1.24 0 01.84 1.18v8.23A3.42 3.42 0 0117 17.58l-4.63 3.05a.75.75 0 01-.37.12zM6.75 6.6v8.05a1.94 1.94 0 001 1.65L12 19.1l4.24-2.78a2 2 0 001-1.67v-8L12 4.79zm8.78 2.93l-1.06-1.06L11 11.94l-1.47-1.47-1.06 1.06L11 14.06z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Shield.displayName = 'Shield';
export default Shield;
