import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Identity: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M13.57 14.25a2.75 2.75 0 001.18-2.2V12a2.75 2.75 0 00-5.5 0 2.75 2.75 0 001.18 2.25h-.15c-2.07.05-3 1.06-3 3.18V18h1.5v-.57c0-1.31.32-1.65 1.54-1.68h3.4c1.24 0 1.56.37 1.56 1.68V18h1.5v-.57c0-2.12-1-3.13-3.05-3.18zM10.75 12a1.25 1.25 0 012.5 0 1.25 1.25 0 01-2.5 0zm7.75-7.75h-3.75V4a.76.76 0 00-.75-.75h-4a.76.76 0 00-.75.75v.25H5.5A1.25 1.25 0 004.25 5.5v14a1.25 1.25 0 001.25 1.25h13a1.25 1.25 0 001.25-1.25v-14a1.25 1.25 0 00-1.25-1.25zm-7.75.5h2.5v1.5h-2.5zm7.5 14.5H5.75V5.75h3.5V7a.76.76 0 00.75.75h4a.76.76 0 00.75-.75V5.75h3.5z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Identity.displayName = 'Identity';
export default Identity;
