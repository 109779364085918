import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ChevronS2Down: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path
          fill='currentColor'
          d='M12 14.06l-3.53-3.53 1.06-1.06L12 11.94l2.47-2.47 1.06 1.06z'
        />
      </g>
    </svg>
  </i>
);

ChevronS2Down.displayName = 'ChevronS2Down';
export default ChevronS2Down;
