import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const AddCircleFilled: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12 3.25A8.75 8.75 0 1020.75 12 8.76 8.76 0 0012 3.25zm4 9.5h-3.25V16h-1.5v-3.25H8v-1.5h3.25V8h1.5v3.25H16z'
        fill='currentColor'
      />
    </svg>
  </i>
);

AddCircleFilled.displayName = 'AddCircleFilled';
export default AddCircleFilled;
export { styles };
