import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Platform: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <rect width={24} height={24} rx='.4' fill='none' />
      <path
        d='M19.79 12a.73.73 0 00-.38-.65L17.05 10l2.36-1.35a.75.75 0 000-1.3l-7-4a.75.75 0 00-.74 0l-7 4a.75.75 0 000 1.3L7 10l-2.33 1.35a.75.75 0 000 1.3L7 14l-2.33 1.35a.75.75 0 000 1.3l7 4a.73.73 0 00.74 0l7-4a.75.75 0 000-1.3L17.05 14l2.36-1.35a.73.73 0 00.38-.65zM12 4.86L17.53 8 12 11.14 6.55 8zM17.53 16L12 19.14 6.55 16l2-1.14 3.13 1.79a.73.73 0 00.74 0l3.13-1.79zM12 15.14L6.55 12l2-1.14 3.13 1.79a.73.73 0 00.74 0l3.13-1.79 2 1.14z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Platform.displayName = 'Platform';
export default Platform;
