import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Login: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M12.19 12.75H3v-1.5h9.19l-1.72-1.72 1.06-1.06L15.06 12l-3.53 3.53-1.06-1.06zm8-1.67l-7.4-7.39A1.3 1.3 0 0011 3.7L6.33 8.33l1.06 1.06 4.49-4.49L19 12l-7.1 7.1-4.51-4.49-1.06 1.06L11 20.31a1.32 1.32 0 00.9.36 1.36 1.36 0 00.93-.37l7.37-7.37a1.3 1.3 0 000-1.85z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Login.displayName = 'Login';
export default Login;
