import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const ProductLiabilities: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g
        fill='none'
        stroke='currentColor'
        strokeLinejoin='round'
        strokeWidth='1.5'
        data-name='Layer 2'
      >
        <circle cx={12} cy={12} r={8} />
        <path d='M8.5 15.5l7-7' />
        <circle cx='14.83' cy='14.83' r='1.5' />
        <circle cx='9.17' cy='9.17' r='1.5' />
      </g>
    </svg>
  </i>
);

ProductLiabilities.displayName = 'ProductLiabilities';
export default ProductLiabilities;
