import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Wallet: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M19 9.25h-.46l-2.16-5.32L14 5.25H7.11a2.83 2.83 0 00-2.82 2.29 2.45 2.45 0 000 .46v9A2.75 2.75 0 007 19.75h12a.76.76 0 00.75-.75v-9a.76.76 0 00-.75-.75zm-2.08 0h-7l5.73-3.18zM5.75 8a1.8 1.8 0 010-.22 1.31 1.31 0 011.34-1h4.2L6.84 9.23a1.21 1.21 0 01-.79-.42 1.23 1.23 0 01-.3-.81zm12.5 10.25H7A1.25 1.25 0 015.75 17v-6.55a2.68 2.68 0 001.25.3h11.25zM16.75 16h-1.5v-3h1.5z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

Wallet.displayName = 'Wallet';
export default Wallet;
