import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Refresh: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path fill='none' d='M0 0h24v24H0z' />
      <path
        d='M10 9.75H4.25V4h1.5v3.43a7.74 7.74 0 0114 4.57h-1.5A6.24 6.24 0 007 8.25h3zm4 6h3A6.24 6.24 0 015.75 12h-1.5a7.74 7.74 0 0014 4.57V20h1.5v-5.75H14z'
        fill='currentColor'
      />
    </svg>
  </i>
);

Refresh.displayName = 'Refresh';
export default Refresh;
