import React from 'react';
import cx from 'classnames';

import styles from './Icons.module.scss';

interface Props {
  className?: Parameters<typeof cx>[0];
}

export const Items: React.FC<Props> = ({ className }) => (
  <i className={cx(styles.icon, className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M18.5 4.25h-13A1.25 1.25 0 004.25 5.5v5a1.25 1.25 0 001.25 1.25h13a1.25 1.25 0 001.25-1.25v-5a1.25 1.25 0 00-1.25-1.25zm-.25 6H5.75v-4.5h12.5zm.25 3h-13a1.25 1.25 0 00-1.25 1.25v5a1.25 1.25 0 001.25 1.25h13a1.25 1.25 0 001.25-1.25v-5a1.25 1.25 0 00-1.25-1.25zm-.25 6H5.75v-4.5h12.5zM7 17a1 1 0 111 1 1 1 0 01-1-1zm4 0a1 1 0 111 1 1 1 0 01-1-1zm4 0a1 1 0 111 1 1 1 0 01-1-1zm1-8.25H8v-1.5h8z'
        fill='currentColor'
      />
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  </i>
);

Items.displayName = 'Items';
export default Items;
